import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { callGetEndpoint, updateInputField } from "../../../api-calls/readApiService";
import { isEmptyObject } from "../../../utilities/helpers";
import { addNotification } from "../../../api-utilities/notification-tools";
import { formatNumber } from "../../../api-utilities/formatting";
import "../../../css/Accordions.scss";
import { getFilteredValidationErrors, getInputCSS, getNewValidationErrorsList, getValidationError } from "../../../utilities/pageUtils";
import { updateUnitDesign } from "../../../api-utilities/performanceUpdate";

// expected props
interface IProps {
  match: match<any>;
  updateRedux: any;
  reduxComponentPerformance:any;
  targetVertex: string;
  reduxMenus: any;
}
// local state
interface IState {
  electricHeateroperatingMode: any;
  preheatElectricHeateroperatingMode: any;
  electricHeaterInputs: any;
  dataContainer: any;
  displayFields:any;
  validationErrors: any[];
  lastUpdatedTextbox: string;
}
class ElectricHeater extends React.Component<IProps, IState> {
  public state: IState = {
    electricHeateroperatingMode: null,
    preheatElectricHeateroperatingMode: null,
    electricHeaterInputs: null,
    dataContainer: null,
    displayFields: null,
    validationErrors: [],
    lastUpdatedTextbox: ""
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    const result = await callGetEndpoint(`readapi/GetElectricHeaterInputValues/${this.props.targetVertex}`, projectId, unitId);

    if (result.uiDataContainer) {
      this.setState({
        electricHeateroperatingMode: result.uiDataContainer.electricHeaterOpMode,
        preheatElectricHeateroperatingMode: result.uiDataContainer.preheatElectricHeaterOpMode,
        dataContainer: result.uiDataContainer, //contains all the extra data fields
        displayFields: result.uiDataContainer.displayFields,
        electricHeaterInputs: result.uiDataContainer.electricHeaterInputs,
        validationErrors: result.validationErrors
      });
    }
  }
  //--------------------------RUN PERFORMANCE---------------------------------
  private async RunPerformance(targetVertex: string){
    const { projectId, unitId } = this.props.match.params;
   
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    //Run performance to update read-only props for filters and update optimized design numbers.
    let perResponse = await updateUnitDesign(projectId, unitId);
    const result = await callGetEndpoint(`readapi/GetElectricHeaterInputValues/${this.props.targetVertex}`, projectId, unitId);

    if (result.hasError) {
      const notification = {
        id: "ElectricHeaterError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetElectricHeaterInputValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (result.uiDataContainer && result.uiDataContainer.filterInputs) {
      this.setState({
        electricHeateroperatingMode: result.uiDataContainer.electricHeaterOpMode,
        preheatElectricHeateroperatingMode: result.uiDataContainer.preheatElectricHeaterOpMode,
        dataContainer: result.uiDataContainer, //contains all the extra data fields
        displayFields: result.uiDataContainer.displayFields,
        electricHeaterInputs: result.uiDataContainer.electricHeaterInputs,
        validationErrors: result.validationErrors
      });
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
    this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: false });
  }
    //Whenever we update a field, remove any previous error for that field
    //Also reset the lastUpdatedTextbox in state

    //*************** Methods only apply when it's a SecondaryElectricHeater ********************
    public removeValidationError(fieldName: string) {
      let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
      this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
    }
    //Check for errors on this textbox/selectbox, and return the appropriate CSS className
    public getCSS(fieldName: string, type: string) {
      return getInputCSS(this.state.validationErrors, fieldName, type);
    }
    public getValidationError(fieldName: string, className: string = "") {
      //Call the validation method from pageUtils
      let cssClass = className === "" ? "validation-error1" : "validation-error-float";
      return getValidationError(this.state.validationErrors, fieldName, cssClass);
    }
    //Add any validation errors we got from the server to state
    public addValidationErrors(validationErrors: any[]) {
      let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
      this.setState({ validationErrors: newList });
    }
    public async updateTextboxValue(fieldName: string, newValue: string) {
      const newState = Object.assign({}, this.state.electricHeaterInputs, { [fieldName]: newValue });
      this.setState({ electricHeaterInputs: newState, lastUpdatedTextbox: fieldName });
    }
    //This gets called by textbox onBlur events
    public async saveTextboxValue(fieldName: string, newValue: string) {
      //If field value hasn't changed, don't call the server
      if (this.state.lastUpdatedTextbox !== fieldName) {
        return;
      }
      this.saveInputValue(fieldName, newValue);
    }
  
    //Save new value to redis and DB
    public async saveInputValue(fieldName: string, newValue: string) {
      const { projectId, unitId } = this.props.match.params;
  
      this.removeValidationError(fieldName);

      //The target vertex for inputs at this point is SecondaryElectricHeater.  The other heater types do not have inputs yet.
      let targetVertex = this.state.displayFields.isSecondaryHeating ? "SecondaryElectricHeaterInputs" : "ElectricHeaterInputs";
      let result = await updateInputField("updateElectricHeater", projectId, unitId, fieldName, newValue, targetVertex);
  
      if (result.success) {
        this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: true });
        //If there are any errors in the response, add them to state
        if (result.data.validationErrors != null) {
          this.addValidationErrors(result.data.validationErrors);
        }
  
        //If uiDataContainer was updated with new values, then update it in state
        if (result.data.uiDataContainer != null) {
          this.setState({
            electricHeaterInputs: result.data.uiDataContainer.electricHeaterInputs,
          });
        }
      }
    }
    //*************** End Of Methods only apply when it's a SecondaryElectricHeater ********************
  GetTitle(targetVertex: string){
    if (targetVertex === "ElectricHeater"){
      return "Electric Heater";
    }
    else if (targetVertex === "PreheatElectricHeater") {
      return "Electric Preheat";
    }
    else {
      return "";
    }
  }
  public render() {
    let dataContainer = this.state.dataContainer;
    if (isEmptyObject(dataContainer)) {
      return null;
    }
    let targetVertex = this.props.targetVertex;
    let title = this.GetTitle(targetVertex);

    //SecondaryHeating can only exist on Electric Heaters and not Preheat Electric heaters.
    //Note: If user selects Electric Heater for Heating Type, then Secondary Heaters are not allowd.
    //The target vertex coming in is "ElectricHeater" for both an Electric heater that is chosen as the heating
    //type and a Secondary Electric Heater.  PreHeat Electric Heater has a target vertex of "PreheatElectricHeater"
    let isSecondaryHeating = this.state.displayFields.isSecondaryHeating && targetVertex === "ElectricHeater";

    let electricHeater = {} as any;
    let operatingMode = {} as any;
    let powerKw = null;
    let amps = null;
   
    //Only populated if it's a SecondaryElectricHeater.
    const savedValues = this.state.electricHeaterInputs;
    
    if (this.props.targetVertex == "ElectricHeater") {
      electricHeater = this.props.reduxComponentPerformance.electricHeater;
      operatingMode = this.state.electricHeateroperatingMode;
    }
    if (this.props.targetVertex == "PreheatElectricHeater") {
      electricHeater = this.props.reduxComponentPerformance.preheatElectricHeater;
      operatingMode = this.state.preheatElectricHeateroperatingMode;
    }
    //EnteringDryBulb for an Electric Heater is for display only and comes from the operatingMode.
    //EnteringDryBulb for a Secondary Electric heater is editable and will show
    //1. the value from the operatingMode if the value has not been edited (enteringDryBulbTemperature from electricHeaterInputs will be null)
    //2. the value from electricHeaterInputs only after the value has been edited.
    let enteringDryBulbValueToShow = savedValues?.enteringDryBulbTemperature === null ? operatingMode.enteringDryBulb : savedValues?.enteringDryBulbTemperature;

    if (isEmptyObject(electricHeater)) {
      return null;
    }
    else{ //We need to show the total PowerKw and Amps
      if(electricHeater.powerKWPerHeater !== null && electricHeater.quantityInHeight !== null && electricHeater.quantityInWidth !== null){
        powerKw = electricHeater.powerKWPerHeater * electricHeater.quantityInHeight * electricHeater.quantityInWidth;
      }
      if(electricHeater.amperagePerHeater !== null && electricHeater.quantityInHeight !== null && electricHeater.quantityInWidth !== null){
        amps = electricHeater.amperagePerHeater * electricHeater.quantityInHeight * electricHeater.quantityInWidth;
      }
    }
    if (!operatingMode) {
      return null;
    }

    if (isEmptyObject(operatingMode)) {
      const notification = {
        id: "elecOperatingModeError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">{targetVertex} operating mode not found.</div>
      };
      addNotification(notification);
      operatingMode = {};
    }

    return (
      <fieldset className="optimize-fieldset">
        <legend className="optimize-legend">{title}</legend>

        <div className="accordion-content">
          <div className="acc-fan-spacer"></div>

          <div className="accordion-row">
            <div className="accordion-label">Entering Air Temp</div>
            
            {!isSecondaryHeating ? 
            <div className="accordion-input">
              <div className="accordion-label-numeric2">{formatNumber(operatingMode.enteringDryBulb, 1) || ""}</div>
            </div>
            :
            <div className="accordion-input">
              <input
                type="text"
                className={this.getCSS("enteringDryBulbTemperature", "accordiontextbox")}
                value={formatNumber(enteringDryBulbValueToShow, 1)}
                onChange={(event) => this.updateTextboxValue("enteringDryBulbTemperature", event.currentTarget.value)}
                onBlur={(event) => this.saveTextboxValue("enteringDryBulbTemperature", event.currentTarget.value)}
              />
              {this.getValidationError("enteringDryBulbTemperature", "validation-error-float")}
            </div>
            }

            <div className="accordion-label">Leaving Air Temp</div>
            <div className="accordion-label-numeric">{formatNumber(operatingMode.leavingDryBulb, 1) || ""}</div>
          </div>

          <div className="accordion-row">
            <div className="accordion-label">Voltage</div>
            <div className="accordion-input">
              <div className="accordion-label-numeric2">{dataContainer.voltage}</div>
            </div>
            <div className="accordion-label">Power (kW)</div>
            <div className="accordion-label-numeric">{formatNumber(powerKw, 1) || ""}</div>
          </div>

          <div className="accordion-row">
            <div className="accordion-label">Control Type</div>
            <div className="accordion-input">
              <div className="accordion-label-numeric2">{dataContainer.controlType}</div>
            </div>
            <div className="accordion-label">Amps</div>
            <div className="accordion-label-numeric">{formatNumber(amps, 1) || ""}</div>
          </div>

          <div className="accordion-row"></div>
          {isSecondaryHeating ? 
          <>
          <div className={this.props.reduxMenus.optimizedDesignInputChanged ? "perf-reset-btn-small drawing-update-design-margin" : "perf-reset-btn-small-disabled drawing-update-design-margin"}>
            <div onClick={() => this.RunPerformance(this.props.targetVertex)}>Update Design</div>
          </div>
          <div className="accordion-row"></div>
          </>
          : null}
        </div>
      </fieldset>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxComponentPerformance: state.reduxComponentPerformance,
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ElectricHeater);
