import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../../redux/actions";
import AccordionHeader from "../../../Shared/AccordionHeader";
import { addNotification } from "../../../../api-utilities/notification-tools";
import {
  getValidationError,
  getSelectBoxOptions,
  getInputCSS,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../../utilities/pageUtils";
import { callGetEndpoint, updateInputField } from "../../../../api-calls/readApiService";
import "../../../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  // reduxAccuPanel: AccuPanel;
  match: match<any>;
}
// local state
interface IState {
  openAccordion: boolean;
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
}

class WaterSourceHeatPump extends React.Component<IProps, IState> {
  public state: IState = {
    openAccordion: false,
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: ""
  };

  private togglePanel() {
    this.setState({ openAccordion: !this.state.openAccordion }); //toggle to opposite of current state
  }
  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    const result = await callGetEndpoint("readapi/GetRefrigerationInputValues", projectId, unitId);
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });

    if (result.hasError){
      const notification = {
        id: "refrigerationInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetRefrigerationInputValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }
    
    if (result.uiDataContainer && result.uiDataContainer.refrigerationInputs) {
      this.setState({
        userInputs: result.uiDataContainer.refrigerationInputs,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors
      });
    }
  }

  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateRefrigeration", projectId, unitId, fieldName, newValue, "RefrigerationInputs");

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.refrigerationInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }

  //------------------------------------------------------------------------------------------
  public render() {
    const active = this.state.openAccordion;
    const savedValues = this.state.userInputs;
    if (!savedValues) {
      return null;
    }

    return (
      <div className="">
        <AccordionHeader
          title="Refrigeration - Packaged WSHP"
          subtitle=""
          classname="accordion-btn acc-btn-1000"
          active={this.state.openAccordion}
          locked={false}
          hasError={false}
          togglePanel={() => this.togglePanel()}
        />

        <div className={`acc-panel-1000 ${active === true ? "acc-panel-active" : "acc-panel-inactive"}`}>
          <div className="accordion-content app-box-shadow">
            <div className="spec-fields">
              <div className="spec-field-spacer1"></div>

              {/* Water Valve Type */}
              <div className="spec-input-label-2">Water Valve Type</div>
              <div className="spec-input-2">
                <select
                  className="spec-selectbox"
                  value={savedValues.waterValveType || ""}
                  onChange={(event) => this.saveInputValue("waterValveType", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "waterValveTypeOptions")}
                </select>
                {this.getValidationError("waterValveType")}
              </div>

              <div className="spec-field-spacer2"></div>

              {/* Vestibule Heater */}
              <div className="spec-input-label-2">Vestibule Heater</div>
              <div className="spec-input-2">
                <select
                  className="spec-selectbox"
                  value={savedValues.vestibuleHeater || ""}
                  onChange={(event) => this.saveInputValue("vestibuleHeater", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "vestibuleHeaterOptions")}
                </select>
                {this.getValidationError("vestibuleHeater")}
              </div>

              <div className="spec-field-spacer1"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    //reduxAccuPanel: state.reduxAccuPanel
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(WaterSourceHeatPump);
