import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { updateInputField } from "../../../api-calls/readApiService";
import {
  getValidationError,
  getChecked,
  getSelectBoxOptions,
  getInputCSS,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../utilities/pageUtils";
import { isEmptyObject } from "../../../utilities/helpers";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  //reduxUnitCabinet: UnitCabinetUI;
  componentData: any;
}

// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
}

class Interior extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: ""
  };

  async componentDidMount() {
    const componentData = this.props.componentData;
    if (!isEmptyObject(componentData)) {
      this.setState({
        userInputs: componentData.uiDataContainer.fineTuneInputs,
        displayFields: componentData.uiDataContainer.displayFields,
        dropdownOptions: componentData.dropdownOptions,
        validationErrors: componentData.validationErrors
      });
    }
  }

  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn"t changed, don"t call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateUnitCabinet", projectId, unitId, fieldName, newValue, "FineTuneInputs");

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.fineTuneInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  //------------------------------------------------------------------------------------------
  public render() {
    //const reduxUnitCabinet = this.props.reduxUnitCabinet;
    const savedValues = this.state.userInputs;
    const displayFields = this.state.displayFields;

    if (!savedValues) {
      return null;
    }
    //let toolTipDisplay = reduxUnitCabinet.coolingOptionSelected && reduxUnitCabinet.humidifierManifoldSharesDrainPanWithCooling ? "" : "app-display-none";
    let toolTipDisplay = displayFields.coolingOptionSelected && savedValues.humidifierManifoldSharesDrainPanWithCooling ? "" : "app-display-none";

    return (
      <div className="spec-fields">
        <div className="spec-field-spacer1"></div>

        <div className="spec-heading-2">Interior Cabinet Construction</div>

        <div className="spec-elec-fields">
          {/* {savedValues.interiorMaterial != null ? ( */}
          <div>
            <div className="spec-input-label-3B">Wall Interior Material</div>
            <div className="spec-input-2">
              <select
                className="spec-selectbox-2"
                value={savedValues.interiorMaterial}
                onChange={(event) => this.saveInputValue("interiorMaterial", event.currentTarget.value)}
                disabled={displayFields.interiorMaterialDisabled}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "interiorMaterialOptions")}
              </select>
              {this.getValidationError("interiorMaterial")}
            </div>
          </div>
          {/* ) : null} */}

          {/* {savedValues.perforatedLiner != null ? ( */}
          <div>
            <div className="spec-input-label-3B">Perforated Liner</div>
            <div className="spec-input-2">
              <select
                className="spec-selectbox-2"
                value={savedValues.perforatedLiner}
                onChange={(event) => this.saveInputValue("perforatedLiner", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "perforatedLinerOptions")}
              </select>
              {this.getValidationError("perforatedLiner")}
            </div>
          </div>
          {/* ) : null} */}

          {/* {savedValues.floorConstruction != null ? ( */}
          <div>
            <div className="spec-input-label-3B">Floor Construction Type</div>
            <div className="spec-input-2">
              <select
                className="spec-selectbox-2"
                value={savedValues.floorConstruction}
                onChange={(event) => this.saveInputValue("floorConstruction", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "floorConstructionOptions")}
              </select>
              {this.getValidationError("floorConstruction")}
            </div>
          </div>
          {/* ) : null} */}

          {/* {savedValues.floorMaterial != null ? ( */}
          <div>
            <div className="spec-input-label-3B">Floor Material</div>
            <div className="spec-input-2">
              <select
                className="spec-selectbox-2"
                value={savedValues.floorMaterial}
                onChange={(event) => this.saveInputValue("floorMaterial", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "floorMaterialOptions")}
              </select>
              {this.getValidationError("floorMaterial")}
            </div>
          </div>
          {/* ) : null} */}

          {displayFields.showSensiblePlateDrainPanMaterial ? (
            <div>
              <div className="spec-input-label-3B">Sensible Plate Drain Pan Material</div>
              <div className="spec-input-2">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.sensiblePlateDrainPanMaterial}
                  onChange={(event) => this.saveInputValue("sensiblePlateDrainPanMaterial", event.currentTarget.value)}
                  disabled={displayFields.sensiblePlateDrainPanMaterialDisabled}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "sensiblePlateDrainPanMaterialOptions")}
                </select>
                {this.getValidationError("sensiblePlateDrainPanMaterial")}
              </div>
            </div>
          ) : null}

          {/* {displayFields.coolingOptionSelected || displayFields.humidifierManifoldSharesDrainPanWithCooling ? ( */}
          {displayFields.showCoolingCoilDrainPanMaterial ? (
            <div>
              <div className="spec-input-label-3B">Cooling Coil Drain Pan Material</div>
              <div className="spec-input-info">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.coolingCoilDrainPanMaterial}
                  onChange={(event) => this.saveInputValue("coolingCoilDrainPanMaterial", event.currentTarget.value)}
                  disabled={displayFields.coolingCoilDrainPanMaterialDisabled}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "coolingCoilDrainPanMaterialOptions")}
                </select>
                {this.getValidationError("coolingCoilDrainPanMaterial")}
                <div className={toolTipDisplay + " tooltip "}>
                  <span className="tooltiptext">Humidifier Manifold and Cooling Coil share a drain pan</span>
                </div>
              </div>
            </div>
          ) : null}

          {/* {displayFields.hasHumidifierManifold && !displayFields.humidifierManifoldSharesDrainPanWithCooling ? ( */}
          {displayFields.showHumidifierManifoldDrainPanMaterial ? (
            <div>
              <div className="spec-input-label-3B">Humidifier Manifold Drain Pan Material</div>
              <div className="spec-input-2">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.humidifierManifoldDrainPanMaterial}
                  onChange={(event) => this.saveInputValue("humidifierManifoldDrainPanMaterial", event.currentTarget.value)}
                  disabled={displayFields.humidifierManifoldDrainPanMaterialDisabled}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "humidifierManifoldDrainPanMaterialOptions")}
                </select>
                {this.getValidationError("humidifierManifoldDrainPanMaterial")}
              </div>
            </div>
          ) : null}

          <div>
            <div className="spec-input-label-3B">Floor Drains</div>
            <div className="floor-drain-input-checkbox">
              <input
                className=""
                type="checkbox"
                checked={getChecked(savedValues.floorDrainsInletAndMixingBoxSections)}
                onChange={(event) => this.saveInputValue("FloorDrainsInletAndMixingBoxSections", event.currentTarget.checked.toString())}
              />
              <div className="floor-drain-checkbox-label">Inlet/mixing box sections</div>
            </div>

            <div className="spec-input-checkbox">
              <input
                className=""
                type="checkbox"
                checked={getChecked(savedValues.floorDrainsOutletSections)}
                onChange={(event) => this.saveInputValue("FloorDrainsOutletSections", event.currentTarget.checked.toString())}
              />
              <div className="floor-drain-checkbox-label">Outlet sections</div>
            </div>
            
            {displayFields.showWSHPVestibuleSections ? 
            <div className="floor-drain-input-checkbox">
              <input
                className=""
                disabled={true}
                type="checkbox"
                checked={getChecked(savedValues.floorDrainsWshpVestibuleSection)}
                onChange={(event) => this.saveInputValue("FloorDrainsWshpVestibuleSection", event.currentTarget.checked.toString())}
              />
              <div className="floor-drain-checkbox-label">WSHP Vesibule section</div>
            </div>
            : null}

            <div className="spec-input-checkbox">
              <input
                className=""
                type="checkbox"
                checked={getChecked(savedValues.floorDrainsAllSections)}
                onChange={(event) => this.saveInputValue("FloorDrainsAllSections", event.currentTarget.checked.toString())}
              />
              <div className="floor-drain-checkbox-label">All sections</div>
            </div>
          </div>

        </div>
        <div className="spec-field-spacer1"></div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxUnitCabinet: state.reduxUnitCabinet
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Interior);
