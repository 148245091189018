import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import { mapDispatchToProps } from "../../redux/actions";
import { formatNumber, RoundUpToNearest } from "../../api-utilities/formatting";
import { callGetEndpoint } from "../../api-calls/readApiService";
import { updateUnitDesign } from "../../api-utilities/performanceUpdate";
import { displayErrorString } from "../../api-utilities/api-calls";
import { getValidationError, getInputCSS } from "../../utilities/pageUtils";
import { addNotification, removeNotification, formatIndividualErrors } from "../../api-utilities/notification-tools";
import OptimizeDesignInputs from "./OptimizeDesignInputs";
import { isEmptyArray, resetReduxModals } from "../../utilities/helpers";
import "../../css/Performance.scss";

// expected props
interface IProps {
  updateRedux: any;
  reduxComponentPerformance: any;
  match: match<any>;
  reduxModals: any;
  reduxMenus: any;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  //This property keeps track of whether the error box relating to height/width dimensions being over the limits
  //has already appeared at least once.  Otherwise it keeps popping up even if the user has closed it.
  overLimitErrorShown: boolean;
  showPrerequisiteErrors: boolean;
  prerequisiteErrorsList: any[];
}

class OptimizeDesign extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    overLimitErrorShown: false,
    showPrerequisiteErrors: false,
    prerequisiteErrorsList: []
  };
  async componentDidMount() {
    await this.runPerformanceCalculations();
    //optimizedDesignInputChanged property determines whether the "Update Design" green button is lit up
    //or not on some of the optimize design accordians.  Since performance was just run, set 
    //optimizedDesignInputChanged to false since all updates have been handled and the buttons should be grey.
    this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: false });
  }
  componentDidUpdate(prevProps: IProps){
    const modal = this.props.reduxModals;

    //If modal state changed from open to closed
    if (prevProps.reduxModals.showModal !== modal.showModal && modal.showModal === false) {
      //If the modal that just closed was the 'reset-design' modal, and the user response was 'ok'
      if (modal.modalId === 'reset-design' && modal.userResponse === 'ok') {
        this.resetToOptimized();
      }
      resetReduxModals();
    }
  }
  private async runPerformanceCalculations() {
    const { projectId, unitId } = this.props.match.params;

    //This fetches read-only component data (not user inputs)
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const optimizeDesignResponse = await updateUnitDesign(projectId, unitId);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });

    //Display prerequisite errors if there are any
    if (!isEmptyArray(optimizeDesignResponse.prerequisiteErrors)) {
      this.setState({ showPrerequisiteErrors: true, prerequisiteErrorsList: optimizeDesignResponse.prerequisiteErrors });
    }

    //only turn off the recalc button if recalculation was successful.
    // if (!optimizeDesignResponse.hasCriticalError) {
    //   this.props.updateRedux("UPDATE_OPTIMIZE_DESIGN", { showRecalcButton: false });
    // }
  }

  private async recalculateUnitDesign() {
    //only run the update if the button is enabled
    // if (this.props.reduxOptimizeDesign.showRecalcButton) {
    //   await this.runPerformanceCalculations();
       //this.props.updateRedux("UPDATE_OPTIMIZE_DESIGN", { showRecalcButton: false });
    // }
  }
  private async confirmReset() {
    const modalData = {
      title: 'Warning',
      content: <div>By resetting, you will lose any modifications made within this screen.</div>
    };
    this.props.updateRedux('UPDATE_MODALS', {
      showModal: true,
      modalType: 'ModalPopup',
      modalId: 'reset-design',
      modalData: modalData,
      hideCancelBtn: false
    });
  }
  private async resetToOptimized() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });

    const optimizeDesignResponse = await callGetEndpoint("writeapi/resetToOptimizedDesign", projectId, unitId);
    this.props.updateRedux('UPDATE_COMPONENT_PERFORMANCE', optimizeDesignResponse);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
    this.props.updateRedux("UPDATE_OPTIMIZE_DESIGN", { showRecalcButton: false });
    this.props.updateRedux("UPDATE_OPTIMIZE_DESIGN", { selectedComponent: null });
    //Update redux to invalidate the unit price
    this.props.updateRedux("UPDATE_PRICING", { priceNeedsUpdate: true });
  }

  private getPrerequisiteErrors() {
    const errors = this.state.prerequisiteErrorsList;
    if (errors) {
      return errors.map((item: any, index: number) => (
        <div key={index}>
          <div className="opt-err1">{item.source}</div>
          <div className="opt-err2">{item.errorMessage}</div>
        </div>
      ));
    }
    return null;
  }

  public render() {
    if (this.state.showPrerequisiteErrors) {
      return (
        <div className="opt-prereq">
          <div className="opt-prereq-title">Warning</div>
          <div className="opt-prereq-msg">Design calculations cannot be run due to missing/invalid fields:</div>
          <div className="opt-prereq-heading">
            <div>Page</div>
            <div>Field</div>
          </div>
          {this.getPrerequisiteErrors()}
          <div className="opt-spacer"></div>

          <div>Please revisit these page(s) and fix any invalid inputs.</div>
        </div>
      );
    }

    const componentPerformanceData = this.props.reduxComponentPerformance;

    if (componentPerformanceData == null) {
      return null;
    }
    let unit = componentPerformanceData.unit;
    if (unit == null) {
      unit = {};
    }
    const isCustomized = unit && unit.isCustomizedDesign ? true : false;

    const optimizedLabel1 = !isCustomized ? "optimize-label1" : "optimize-label1 optimize-label-disabled";
    const optimizedLabel2 = !isCustomized ? "optimize-label2" : "optimize-label2 optimize-label-disabled";
    //const recalcButton = this.props.reduxOptimizeDesign.showRecalcButton ? "optimize-update-btn" : "optimize-update-btn optimize-update-btn-disabled";

    return (
      <div className="">
        <div className="opt-info-panel">
          <div className="nf-body">
            <div className="nf-tr">
              <div className={["nf-left", `nf-info-tab`].join(" ")}></div>
              <div className="nf-right">
                <div className={`nf-info-bar`}>
                  SpecMate has created an optimized design based on your selections.
                  <div className="nf-x"></div>
                </div>
                <div className={`nf-info-content`}>
                  <div className="opt-design-data">
                     <div className="opt-col-category">
                    {/*  {this.props.reduxOptimizeDesign.showRecalcButton ? (
                        <div className="optimize-update-btn2" onClick={() => this.recalculateUnitDesign()}>
                          Apply Design Changes
                        </div>
                      ) : null} */}
                    </div>
                    <div className="opt-col-heading125">Casing Height</div>
                    <div className="opt-col-heading125">Casing Width</div>
                    <div className="opt-col-heading125">Approx. Weight</div>
                    <div className="opt-col-heading180">Heating Leaving Temp</div>
                    <div className="opt-col-heading180">Cooling Leaving Temp</div>
                    <div className="opt-col-heading60">MCA</div>
                    <div className="opt-col-heading60">MOP</div>

                    <div className="opt-spacer-15-line"></div>
                    <div className={`opt-col-category ${isCustomized ? "opt-disabled" : ""}`}>SpecMate-Optimized Design</div>
                    <div className={`opt-col-heading125 ${isCustomized ? "opt-disabled" : ""}`}>{RoundUpToNearest(unit.casingHeightOptimized ?? 0, 1) || "n/a"}</div>
                    <div className={`opt-col-heading125 ${isCustomized ? "opt-disabled" : ""}`}>{RoundUpToNearest(unit.casingWidthOptimized ?? 0, 1) || "n/a"}</div>
                    <div className={`opt-col-heading125 ${isCustomized ? "opt-disabled" : ""}`}>{unit.approxWeightOptimized || "n/a"}</div>
                    <div className={`opt-col-heading180 ${isCustomized ? "opt-disabled" : ""}`}>{formatNumber(unit.heatingLeavingTempOptimized ?? 0, 2)}</div>
                    <div className={`opt-col-heading180 ${isCustomized ? "opt-disabled" : ""}`}>{formatNumber(unit.coolingLeavingTempOptimized ?? 0, 2)}</div>
                    <div className={`opt-col-heading60 ${isCustomized ? "opt-disabled" : ""}`}>{formatNumber(unit.mcaOptimized ?? 0, 1)}</div>
                    <div className={`opt-col-heading60 ${isCustomized ? "opt-disabled" : ""}`}>{formatNumber(unit.mopOptimized ?? 0, 1)}</div>

                    {isCustomized ? (
                      <>
                        <div className="opt-spacer-15"></div>
                        <div className="opt-col-category">User-Modified Design</div>
                        <div className="opt-col-heading125">{unit.casingHeight || "n/a"}</div>
                        <div className="opt-col-heading125">{unit.casingWidth || "n/a"}</div>
                        <div className="opt-col-heading125">{unit.approxWeight || "n/a"}</div>
                        <div className="opt-col-heading180">{formatNumber(unit.heatingLeavingTemp ?? 0, 2)}</div>
                        <div className="opt-col-heading180">{formatNumber(unit.coolingLeavingTemp ?? 0, 2)}</div>
                        <div className="opt-col-heading60">{formatNumber(unit.mca ?? 0, 1)}</div>
                        <div className="opt-col-heading60">{formatNumber(unit.mop ?? 0, 1)}</div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isCustomized ? (
          <div>
            <div className="optimize-reset-label2" onClick={() => this.confirmReset()}>
              Reset to SpecMate-Optimized Design
            </div>
          </div>
        ) : (
          <div className="opt-info-title">Modify the design using the inputs below.</div>
        )}

        {/* {this.showCoolingReheatTemp() ? (
                              <div className="perf-row">
                                <div className={optimizedLabel1}>Cooling Reheat Temp</div>
                                <div className={optimizedLabel2}>{this.formatTempValue(optimizedDesignPerformanceResult, "coolingReheatTemp")}</div>
                              </div>
      ) : null} */}

        <div className="opt-spacer"></div>
        <OptimizeDesignInputs componentPerformanceData={this.props.reduxComponentPerformance} match={this.props.match} />

        <div className="optimize-bottom-spacer"></div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxComponentPerformance: state.reduxComponentPerformance,
    reduxModals: state.reduxModals,
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(OptimizeDesign);
