import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../redux/actions";
import { addNotification } from "../../api-utilities/notification-tools";
import {
  getValidationError,
  getSelectBoxOptions,
  getInputCSS,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../utilities/pageUtils";
import { callGetEndpoint, updateInputField } from "../../api-calls/readApiService";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
}

class FluidConditionsForWSHP extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: ""
  };
  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    const result = await callGetEndpoint("readapi/GetRefrigerationInputValues", projectId, unitId);
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });

    if (result.hasError){
      const notification = {
        id: "refrigerationInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetRefrigerationInputValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }
    
    if (result.uiDataContainer && result.uiDataContainer.refrigerationInputs) {
      this.setState({
        userInputs: result.uiDataContainer.refrigerationInputs,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors
      });
    }
  }

  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateRefrigeration", projectId, unitId, fieldName, newValue, "RefrigerationInputs");

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.refrigerationInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }

  //------------------------------------------------------------------------------------------
  public render() {
    const savedValues = this.state.userInputs;
    if (!savedValues) {
      return null;
    }

    return (
    <div>
        <div className="input-heading">WSHP Fluid Conditions</div>

        <div className="input-fields">
        <div>
            <div className="system-input-label">Fluid Type</div>
            <div className="input-textbox">
              <select
                className={this.getCSS("waterSourceFluidType", "selectbox")}
                value={savedValues.waterSourceFluidType}
                onChange={(event) => this.saveInputValue("waterSourceFluidType", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "waterSourceFluidTypeOptions")}
              </select>
            </div>
            {this.getValidationError("waterSourceFluidType")}
        </div>

        {savedValues.waterSourceFluidType !== "Water" ? (   
        <div>
            <div className="system-input-label">Glycol %</div>
            <div className="input-textbox">
              <input type="text"
                className={this.getCSS("supplyEsp", "textbox")}
                value={savedValues.glycolPercent}
                onChange={(event) => this.updateTextboxValue("glycolPercent", event.currentTarget.value)}
                onBlur={(event) => this.saveTextboxValue("glycolPercent", event.currentTarget.value)}
              />
            </div>
            {this.getValidationError("glycolPercent")}
        </div>
        ) : null}

        <div>
            <div className="system-input-label">Cooling Entering Fluid Temp</div>
            <div className="input-textbox">
            <input
                type="text"
                className={this.getCSS("coolingEnteringFluidTemp", "textbox")}
                value={savedValues.coolingEnteringFluidTemp}
                onChange={(event) => this.updateTextboxValue("coolingEnteringFluidTemp", event.currentTarget.value)}
                onBlur={(event) => this.saveTextboxValue("coolingEnteringFluidTemp", event.currentTarget.value)}
            />
            </div>
            {this.getValidationError("coolingEnteringFluidTemp")}
        </div>

        <div>
            <div className="system-input-label">Heating Entering Fluid Temp</div>
            <div className="input-textbox">
            <input
                type="text"
                className={this.getCSS("heatingEnteringFluidTemp", "textbox")}
                value={savedValues.heatingEnteringFluidTemp}
                onChange={(event) => this.updateTextboxValue("heatingEnteringFluidTemp", event.currentTarget.value)}
                onBlur={(event) => this.saveTextboxValue("heatingEnteringFluidTemp", event.currentTarget.value)}
            />
            </div>
            {this.getValidationError("heatingEnteringFluidTemp")}
        </div>

        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {

  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FluidConditionsForWSHP);
