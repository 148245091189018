import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../redux/actions";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import { getUnitCabinetInputValues } from "../../api-calls/readApiService";
import { isEmptyObject } from "../../utilities/helpers";
import Exterior from "./Cabinet/Exterior";
import Interior from "./Cabinet/Interior";
import Doors from "./Cabinet/Doors";
import "../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
}
// local state
interface IState {
  componentData: any;
}

class UnitCabinet extends React.Component<IProps, IState> {
  public state: IState = {
    componentData: null
  };

  //async componentDidMount() {
  //If both a cooling coil and humidifier manifold are selected, it's possible they could share a drain pan.  If they do share a drain pan,
  //set humidifierManifoldDrainPanMaterial to 0 (none) because the cooling drain pan will be shared.  There is no UI trigger for the calc to
  //determine drain pan sharing, so this workaround is put in place as we can't set it's default value in the backend using the "BeforeModify" framework.
  //If a change is made in which the calc determines that they no longer share a drain pan, then set the humidifierManifoldDrainPanMaterial
  //to it's default of 1 (201 Stainless steel)
  // await getUnitCabinet(this.props.match.params);
  // const { projectId, unitId } = this.props.match.params;
  // if (this.props.reduxUnitCabinet.humidifierManifoldSharesDrainPanWithCooling) {
  //   const response = await modifyServerProperty(this.props.reduxUnitCabinet, "humidifierManifoldDrainPanMaterial", 0, projectId, unitId);
  //   await getUnitCabinet(this.props.match.params);
  // }
  // if (
  //   !this.props.reduxUnitCabinet.humidifierManifoldSharesDrainPanWithCooling &&
  //   this.props.reduxUnitCabinet.humidifierManifoldDrainPanMaterial?.value === 0 &&
  //   this.props.reduxUnitCabinet.hasHumidifierManifold
  // ) {
  //   const response = await modifyServerProperty(this.props.reduxUnitCabinet, "humidifierManifoldDrainPanMaterial", 1, projectId, unitId);
  //   await getUnitCabinet(this.props.match.params);
  // }
  //}
  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const result = await getUnitCabinetInputValues(projectId, unitId);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });

    if (result.hasError){
      const notification = {
        id: "cabinetInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetUnitCabinetInputValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (!isEmptyObject(result.uiDataContainer)) {
      this.setState({
        componentData: result
      });
    }
  }

  public render() {
    const componentData = this.state.componentData;

    if (isEmptyObject(componentData)) {
      return null;
    }
    return (
      <div className="spec-body-content-wide">
        {/* <div className="spec-copy-btn">Copy Values From:</div> */}
        {/* <div className="spec-unit-copy">
          <select className="spec-unit-selectbox" value="" onChange={(event) => {}}>
            <option>choose</option>
            { {this.getSelectOptions('')} }
          </select>
        </div> */}

        <div className="spec-field-spacer2"></div>

        <div className="spec-page-division">
          <Exterior componentData={componentData} match={this.props.match} />
        </div>

        <div className="spec-page-division">
          <Interior componentData={componentData} match={this.props.match} />
        </div>

        <div className="spec-page-division">
          <Doors componentData={componentData} match={this.props.match} />
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UnitCabinet);
