import * as React from "react";
import { connect } from "react-redux";
import { match, matchPath } from "react-router-dom";
import { mapDispatchToProps } from "../../redux/actions";
import {DrawingScreen, DrawingViewType} from '../../api-utilities/enums';
import { DrawingViewer } from "./DrawingViewer";
import {getValidationError, getSelectBoxOptions, getInputCSS, getFilteredValidationErrors, getNewDropdownList, getNewValidationErrorsList} from "../../utilities/pageUtils";
import { callGetEndpoint, updateInputField, getUnitDrawing } from "../../api-calls/readApiService";
import "../../css/UnitDrawing.scss";
import { isEmptyArray } from "../../utilities/helpers";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  history: any;
  reduxMenus: any;
}

// local state
interface IState {
  topDrawingState: IDrawingState;
  bottomDrawingState: IDrawingState;
  renderDefaultDrawing: boolean;
  uiDataContainer: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  completeData: string;
  airTunnelOrientationType:string;
  defaultViewType: DrawingViewType;
  topDrawingAvailableViewTypes: DrawingViewType[];
  showPrerequisiteErrors: boolean;
  prerequisiteErrorsList: any[];
}

interface IDrawingState {
  svg: string;
  drawingView?: DrawingViewType;
}

class CoilConnections extends React.Component<IProps, IState> {
  public state: IState = {
    topDrawingState: { svg: "", drawingView: undefined },
    bottomDrawingState: { svg: "", drawingView: undefined },
    renderDefaultDrawing: false,
    uiDataContainer: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    completeData: "",
    airTunnelOrientationType:"",
    defaultViewType: DrawingViewType.Top,
    topDrawingAvailableViewTypes: [],
    showPrerequisiteErrors: false,
    prerequisiteErrorsList: []
  };

  async componentDidMount() {
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    if (this.props.reduxMenus.enableSave){
      this.setState({renderDefaultDrawing:true});
    }
    const { projectId, unitId } = this.props.match.params;
    const result = await callGetEndpoint("readapi/GetCoilConnectionValues", projectId, unitId);
    this.setState({
      uiDataContainer: result.uiDataContainer,
      displayFields: result.uiDataContainer?.displayFields,
      dropdownOptions: result.dropdownOptions,
      validationErrors: result.validationErrors,
      completeData: result.completeData,
      airTunnelOrientationType: result.airTunnelOrientationType,
      defaultViewType: result.defaultViewType,
      topDrawingAvailableViewTypes: result.topDrawingAvailableViewTypes
    });
    //Display prerequisite errors if there are any
    if (!isEmptyArray(result.prerequisiteErrors)) {
      this.setState({ showPrerequisiteErrors: true, prerequisiteErrorsList: result.prerequisiteErrors });
    }
    else {
      await this.getDefaultDrawings();
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }
  componentDidUpdate(prevProps: any) {
    //If a save was in progress, render the default drawings after it finishes.
    // if (prevProps.reduxMenus.enableSave !== this.props.reduxMenus.enableSave && !this.state.renderDefaultDrawing) {
    //   this.getDefaultDrawings();
    // }
  }
  private async getDefaultDrawings() {
    //Get defualt drawing view for Coil Connections.
    let topDrawingView =
    this.state.topDrawingState.drawingView === undefined
      ? this.state.defaultViewType
      : this.state.topDrawingState.drawingView;
    if (typeof(topDrawingView) === 'undefined'){
      topDrawingView = this.state.airTunnelOrientationType === "Stacked" ? DrawingViewType.TopUpperTunnel : DrawingViewType.Top;
    }
    await this.selectTopDrawingView(topDrawingView);
    await this.selectBottomDrawingView(this.state.bottomDrawingState.drawingView ?? DrawingViewType.FrontSide);
  }

  //Get the updated SVG
  private async getDrawingState(newValue: DrawingViewType) {
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    let completeData = this.state.completeData;
    const response = await getUnitDrawing(this.props.match.params, newValue, DrawingScreen.CoilConnections, completeData);
    let svg = '';
    //let svg = "<svg height=\"300\" viewbox=\"0 0 650 300\" preserveAspectRatio=\"xMidYMid meet\">\r\n  <rect desc=\"unit casing exterior\" x=\"0\" y=\"0\" width=\"640\" height=\"96.75776062334181\" style=\"stroke:black;stroke-width:1;fill:none\" transform=\"translate(10, 101.62111968832909)\" />\r\n  <rect desc=\"primary tunnel interior\" x=\"2.1990400141668593\" y=\"49.47840031875433\" width=\"630.601919971667\" height=\"45.080320290420616\" style=\"stroke:black;stroke-width:1;fill:none;stroke-dasharray:10 5\" transform=\"translate(10, 101.62111968832909)\" />\r\n  <rect desc=\"secondary tunnel interior\" x=\"2.1990400141668593\" y=\"2.1990400141668593\" width=\"6398.601919971667\" height=\"45.080320290420616\" style=\"stroke:black;stroke-width:1;fill:none;stroke-dasharray:10 5\" transform=\"translate(10, 101.62111968832909)\" />\r\n</svg>";
    if (response?.data.drawings?.length) {
      svg = response?.data.drawings[0].unitDrawingSvg;
    }
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
    return { svg: svg ?? '', drawingView: newValue } as IDrawingState;
  }
  private async selectTopDrawingView(newValue: DrawingViewType) {
    this.setState({ topDrawingState: await this.getDrawingState(newValue) });
  }
  private async selectBottomDrawingView(newValue: DrawingViewType) {
    this.setState({ bottomDrawingState: await this.getDrawingState(newValue) });
  }

  private getTopDrawingViewer() {
    return (
      <DrawingViewer
        drawingScreen={DrawingScreen.CoilConnections}
        availableViewTypes={this.state.topDrawingAvailableViewTypes ?? []}
        drawingViewInfo={this.state.topDrawingState}
        selectDrawingView={this.selectTopDrawingView.bind(this)}
      ></DrawingViewer>
    );
  }
  private getBottomDrawingViewer() {
    return (
      <DrawingViewer
        drawingScreen={DrawingScreen.CoilConnections}
        availableViewTypes={[DrawingViewType.FrontSide, DrawingViewType.BackSide]}
        drawingViewInfo={this.state.bottomDrawingState}
        selectDrawingView={this.selectBottomDrawingView.bind(this)}
      ></DrawingViewer>
    );
  }
  //------------------------------------------------------------------------------------------
  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string, targetVertex: string) {
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateCoilConnections", projectId, unitId, fieldName, newValue, targetVertex);

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          uiDataContainer: result.data.uiDataContainer,
          displayFields: result.data.uiDataContainer.displayFields,
          completeData: result.data.completeData
        });
        this.getDefaultDrawings();
      }
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  private getPrerequisiteErrors() {
    const errors = this.state.prerequisiteErrorsList;
    if (errors) {
      return errors.map((item: any, index: number) => (
        <div key={index}>
          <div className="opt-err1">{item.source}</div>
          <div className="opt-err2">{item.errorMessage}</div>
        </div>
      ));
    }
    return null;
  }
  //------------------------------------------------------------------------------------------
  public render() {
    if (this.state.showPrerequisiteErrors) {
      return (
        <div className="opt-prereq">
          <div className="opt-prereq-title">Warning</div>
          <div className="opt-prereq-msg">Design calculations cannot be run due to missing/invalid fields:</div>
          <div className="opt-prereq-heading">
            <div>Page</div>
            <div>Field</div>
          </div>
          {this.getPrerequisiteErrors()}
          <div className="opt-spacer"></div>

          <div>Please revisit these page(s) and fix any invalid inputs.</div>
        </div>
      );
    }
    const displayFields = this.state.displayFields;
    const uiDataContainer = this.state.uiDataContainer;
    if (!uiDataContainer) {
      return null;
    }
    const dxCoilFieldSetClass = displayFields.showWaterConnectionsPenetration ? "wshp-dxcoil-fieldset" : "cwcoil-fieldset";
    const dxCoilLegendClass = displayFields.showWaterConnectionsPenetration ? "wshp-dxcoil" : "cwcoil-legend";
    const preHeatHotWaterUnitPenetration = uiDataContainer.preHeatHotWaterCoilInputs ? uiDataContainer.preHeatHotWaterCoilInputs.unitPenetration : "";
    const chilledWaterUnitPenetration = uiDataContainer.chilledWaterCoilInputs ? uiDataContainer.chilledWaterCoilInputs.unitPenetration : "";
    const hotWaterUnitPenetration = uiDataContainer.hotWaterCoilInputs ? uiDataContainer.hotWaterCoilInputs.unitPenetration : "";
    const steamUnitPenetration = uiDataContainer.steamCoilInputs ? uiDataContainer.steamCoilInputs.unitPenetration : "";
    const dxUnitPenetration = uiDataContainer.refrigerationInputs ? uiDataContainer.refrigerationInputs.dxUnitPenetration : "";
    const hotGasReheatUnitPenetration = uiDataContainer.refrigerationInputs ? uiDataContainer.refrigerationInputs.hgrhUnitPenetration : "";
    const waterConnectionsUnitPenetration = uiDataContainer.refrigerationInputs ? uiDataContainer.refrigerationInputs.waterConnectionsUnitPenetration : "";
    return (
      <div className="drawing-inputs-main">
        <div className="drawing-container">
          <div className="drawing-left">
            <div className="drawing-fields-left">
              {/* ================== Preheat Hot Water Coil Connections =========================== */}
              {displayFields.showPreHeatHotWaterCoil ? (
                <fieldset className={"drawing-fieldset hwcoil-fieldset legend-spacer"}>
                  <legend className={"drawing-legend hwcoil-legend"}>Preheat Hot Water Coil Connections</legend>

                  <div className="drawing-fields-spacer"></div>

                  <div className="input-fields">
                    <div className="accordion-row">
                      <div className="accordion-label">Unit Penetration</div>

                      <div className="accordion-input">
                        <select
                          className={getInputCSS(this.state.validationErrors, "preHeatHotWaterUnitPenetration", "accordion-select")}
                          value={preHeatHotWaterUnitPenetration || ""}
                          onChange={(event) => this.saveInputValue("unitPenetration", event.currentTarget.value, "PreHeatHotWaterCoilInputs")}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "preHeatFluidPenetrationOptions")}
                        </select>
                        {this.getValidationError("preHeatHotWaterUnitPenetration")}
                      </div>
                    </div>
                  </div>

                  <div className="drawing-fields-spacer"></div>
                  <div className="drawing-fields-spacer"></div>
                </fieldset>
              ) : null}

              {/* ================== Chilled Water Coil Connections =========================== */}
              {displayFields.showChilledWaterCoil ? (
                <fieldset className={"drawing-fieldset cwcoil-fieldset legend-spacer"}>
                  <legend className={"drawing-legend cwcoil-legend"}>Chilled Water Coil Connections</legend>

                  <div className="drawing-fields-spacer"></div>

                  <div className="input-fields">
                    <div className="accordion-row">
                      <div className="accordion-label">Unit Penetration</div>

                      <div className="accordion-input">
                        <select
                          className={getInputCSS(this.state.validationErrors, "chilledWaterUnitPenetration", "accordion-select")}
                          value={chilledWaterUnitPenetration || ""}
                          onChange={(event) => this.saveInputValue("unitPenetration", event.currentTarget.value, "ChilledWaterCoilInputs")}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "fluidCoilPenetrationOptions")}
                        </select>
                        {this.getValidationError("chilledWaterUnitPenetration")}
                      </div>
                    </div>
                  </div>

                  <div className="drawing-fields-spacer"></div>
                  <div className="drawing-fields-spacer"></div>
                </fieldset>
              ) : null}

              {/* ================== Hot Water Coil Connections =========================== */}
              {displayFields.showHotWaterCoil ? (
                <fieldset className={"drawing-fieldset hwcoil-fieldset legend-spacer"}>
                  <legend className={"drawing-legend hwcoil-legend"}>Hot Water Coil Connections</legend>

                  <div className="drawing-fields-spacer"></div>

                  <div className="input-fields">
                    <div className="accordion-row">
                      <div className="accordion-label">Unit Penetration</div>

                      <div className="accordion-input">
                        <select
                          className={getInputCSS(this.state.validationErrors, "hotWaterUnitPenetration", "accordion-select")}
                          value={hotWaterUnitPenetration || ""}
                          onChange={(event) => this.saveInputValue("unitPenetration", event.currentTarget.value, "HotWaterCoilInputs")}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "fluidCoilPenetrationOptions")}
                        </select>
                        {this.getValidationError("hotWaterUnitPenetration")}
                      </div>
                    </div>
                  </div>

                  <div className="drawing-fields-spacer"></div>
                  <div className="drawing-fields-spacer"></div>
                </fieldset>
              ) : null}

              {/* ================== Steam Coil Connections =========================== */}
              {displayFields.showSteamCoil ? (
                <fieldset className={"drawing-fieldset hwcoil-fieldset legend-spacer"}>
                  <legend className={"drawing-legend hwcoil-legend"}>Steam Coil Connections</legend>

                  <div className="drawing-fields-spacer"></div>

                  <div className="input-fields">
                    <div className="accordion-row">
                      <div className="accordion-label">Unit Penetration</div>

                      <div className="accordion-input">
                        <select
                          className={getInputCSS(this.state.validationErrors, "steamUnitPenetration", "accordion-select")}
                          value={steamUnitPenetration || ""}
                          onChange={(event) => this.saveInputValue("unitPenetration", event.currentTarget.value, "SteamCoilInputs")}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "fluidCoilPenetrationOptions")}
                        </select>
                        {this.getValidationError("steamUnitPenetration")}
                      </div>
                    </div>
                  </div>

                  <div className="drawing-fields-spacer"></div>
                  <div className="drawing-fields-spacer"></div>
                </fieldset>
              ) : null}

              {/* ================== Direct Expansion Coil Connections =========================== */}
              {displayFields.showDxCoil ? (
                <fieldset className={dxCoilFieldSetClass + " drawing-fieldset legend-spacer"}>
                  <legend className={dxCoilLegendClass + " drawing-legend"}>Direct Expansion Coil Connections</legend>

                  <div className="drawing-fields-spacer"></div>

                  <div className="input-fields">
                    <div className="accordion-row">
                      <div className="accordion-label">Unit Penetration</div>

                      <div className="accordion-input">
                        <select
                          className={this.getCSS("dxUnitPenetration", "accordion-select")}
                          value={dxUnitPenetration || ""}
                          onChange={(event) => this.saveInputValue("dxUnitPenetration", event.currentTarget.value, "RefrigerationInputs")}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "refrigerationPenetrationOptions")}
                        </select>
                        {this.getValidationError("dxUnitPenetration")}
                      </div>
                    </div>
                  </div>

                  <div className="drawing-fields-spacer"></div>
                  <div className="drawing-fields-spacer"></div>
                </fieldset>
              ) : null}

              {/* ================== Water Connections Penetration (WSHP)=========================== */}
              {displayFields.showWaterConnectionsPenetration ? (
                <fieldset className={"drawing-fieldset cwcoil-fieldset legend-spacer"}>
                  <legend className={"drawing-legend cwcoil-legend"}>Water Connections</legend>

                  <div className="drawing-fields-spacer"></div>

                  <div className="input-fields">
                    <div className="accordion-row">
                      <div className="accordion-label">Unit Penetration</div>

                      <div className="accordion-input">
                        <select
                          className={this.getCSS("waterConnectionsUnitPenetration", "accordion-select")}
                          value={waterConnectionsUnitPenetration || ""}
                          onChange={(event) => this.saveInputValue("waterConnectionsUnitPenetration", event.currentTarget.value, "RefrigerationInputs")}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "waterConnectionsUnitPenetrationOptions")}
                        </select>
                        {this.getValidationError("waterConnectionsUnitPenetration")}
                      </div>
                    </div>
                  </div>

                  <div className="drawing-fields-spacer"></div>
                  <div className="drawing-fields-spacer"></div>
                </fieldset>
              ) : null}

              {/* ================== Hot Gas Reheat Connections =========================== */}
              {displayFields.showHotGasReheatCoil ? (
                <fieldset className={"drawing-fieldset hwcoil-fieldset legend-spacer"}>
                  <legend className={"drawing-legend hwcoil-legend"}>Hot Gas Reheat Connections</legend>

                  <div className="drawing-fields-spacer"></div>

                  <div className="input-fields">
                    <div className="accordion-row">
                      <div className="accordion-label">Unit Penetration</div>

                      <div className="accordion-input">
                        <select
                          className={this.getCSS("hgrhUnitPenetration", "accordion-select")}
                          value={hotGasReheatUnitPenetration || ""}
                          onChange={(event) => this.saveInputValue("hgrhUnitPenetration", event.currentTarget.value, "RefrigerationInputs")}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "refrigerationPenetrationOptions")}
                        </select>
                        {this.getValidationError("hgrhUnitPenetration")}
                      </div>
                    </div>
                  </div>

                  <div className="drawing-fields-spacer"></div>
                  <div className="drawing-fields-spacer"></div>
                </fieldset>
              ) : null}
            </div>
          </div>

          <div className="drawing-right">
            <div className="drawing-heading">Unit Drawing Preview</div>

            {this.getTopDrawingViewer()}

            {this.getBottomDrawingViewer()}
          </div>

          <div className="drawing-td3">{/* Take up remaining horizontal space in browser */}</div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CoilConnections);
